import {usePlaidLink } from 'react-plaid-link';

function PlaidLinkToken(props) {

  const config = {
    onSuccess: (public_token, metadata) => {
      alert('Account Connection Refreshed!');
      console.log(public_token);
      console.log(metadata);
      props.onPlaidSuccess();
    },
    onExit: (err, metadata) => {
      console.log(err);
      console.log(metadata);
      alert('Something went wrong, please try again later. (Plaid)');
    },
    token: props.plaidLinkToken,
  };
  const { open, ready, error } = usePlaidLink(config);

  if (error) {
    console.log(error);
  }

  if (ready) {
    open();
  }
}

export default PlaidLinkToken;